const IconHouse = () => (
    <svg viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.9008 22.2793H12.0491C11.8523 22.2793 11.6914 22.4402 11.6914 22.6369V38.9993H21.2585V22.6369C21.2585 22.4402 21.0975 22.2793 20.9008 22.2793Z"
            stroke="#feca01"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
        <path
            d="M1 13.7016V37C1 38.1046 1.89543 39 3 39H29.7331C30.8377 39 31.7331 38.1046 31.7331 37V14.1358C31.7331 13.52 31.4494 12.9385 30.964 12.5594L28.8703 10.9247L17.5442 1.96489C16.8267 1.39733 15.8156 1.38891 15.0888 1.94443L1.78548 12.1126C1.29042 12.491 1 13.0785 1 13.7016Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

export default IconHouse;
